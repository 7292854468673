<template>
  <div>
    <form-wizard
      color="#AEBDC4"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Planificar"
      back-button-text="Anterior"
      next-button-text="Siguiente"
      class="mb-3"
      @on-complete="formOnComplete"
      @on-change="formOnChange"
    >
    <!--
      <tab-content
        title="Demanda y Precios"
        :before-change="beforeDemandaYPreciosTabSwitch"
      >
        <demanda-y-precios-tab
          ref="demanda-y-precios-tab"
        ></demanda-y-precios-tab>
      </tab-content>
      -->
     <!--
      <tab-content
        title="Lineas Productivas"
        :before-change="lineasProductivasTabSwitch"
      >
        <lineas-productivas-tab
          ref="lineas-productivas-tab"
        ></lineas-productivas-tab>
      </tab-content>
      -->
      <!--
      <tab-content
        title="Ajustes Productivos"
        :before-change="beforeConfirmarAjustesTabSwitch"
      >
        <ajustes-productivos-tab
          ref="ajustes-productivos-tab"
        ></ajustes-productivos-tab>
      </tab-content>
      -->
      <!--
      <tab-content
        title="Confirmar Planificación"
        :before-change="beforeConfirmarPlanificacionTabSwitch"
      >
        <confirmar-planificacion-tab
          ref="confirmar-planificacion-tab"
          :data-validada="data_validada"
        ></confirmar-planificacion-tab>
      </tab-content>
-->

    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";


//import DemandaYPreciosTab from "./form-wizard-tabs/UltimoDatoDisponibleTab.vue";
//import LineasProductivasTab from "./form-wizard-tabs/PrecioMercadoUsaTab.vue";
//import AjustesProductivosTab from "./form-wizard-tabs/ConfirmarAjustesCapacidadTab.vue";
//import ConfirmarPlanificacionTab from "./form-wizard-tabs/ConfirmarPlanificacionTab.vue";


 
import
{
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BToast
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BToast,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 

    //DemandaYPreciosTab,
    //LineasProductivasTab,
    //AjustesProductivosTab,
    //ConfirmarPlanificacionTab,
  },
  data()
  {


    return {
        data_validada: {
          ultimoDatoDisponible: {},
          precioMercadoUsa: {}

        }
    };
  },
  methods: {

    beforeConfirmarAjustesTabSwitch()
    {
      return this.$refs['ajustes-productivos-tab'].validate()
    },

    beforeConfirmarPlanificacionTabSwitch()
    {
      return this.$refs['confirmar-planificacion-tab'].validate()
    },

    lineasProductivasTabSwitch()
    {
      let validated = this.$refs['lineas-productivas-tab'].validate()

      if(validated)
      {
        this.data_validada.precioMercadoUsa = this.$refs['lineas-productivas-tab'].getData()
        return validated;

      }


    },

    beforeDemandaYPreciosTabSwitch()
    {
      let validated = this.$refs['demanda-y-precios-tab'].validate()


      if(validated)
      {
        this.data_validada.ultimoDatoDisponible = this.$refs['demanda-y-precios-tab'].getData()
        return validated;
      }


    },

    formOnChange(prevIndex, nextIndex)
    {
    },

    formOnComplete()
    {
     



    },

    formSubmitted()
    {
      console.log(this.formInput);

      let self = this;

      this.$http.get("/api/crear_planificacion").then((res) =>
      {

        let planificacion_id = res.data;

        this.successToast('Se le notificará a su correo cuando los resultados estén disponibles');

        let tiempo_total = 1 * 60 * 1000;
        let tiempo_acumulado = 0;
        let delay = 5 * 1000;

        let interval = setInterval(function ()
        {

          tiempo_acumulado += delay;

          if (tiempo_acumulado >= tiempo_total)
          {
            clearInterval(interval);

            self.$http.get("/api/finalizar_planificacion/" + planificacion_id).then((res) =>
            {

              self.finalPlanificacion('La planificación ha finalizado')

              console.log(res.data);


            });

          }
          else
          {
            let porcentaje = (100 * (tiempo_acumulado / tiempo_total)).toFixed(2)

            self.progresoToast('Progreso: ' + porcentaje + '%');

          }


        }, delay);




      });






    },
    successToast(message)
    {
      this.$bvToast.toast(message, {
        title: 'Planificación enviada exitosamente',
        variant: 'success',
        solid: true,
        autoHideDelay: 2000,
        noHoverPause: true,
        appendToast: true,
      });
    },

    progresoToast(message)
    {
      this.$bvToast.toast(message, {
        title: 'Progreso Planificación',
        variant: 'info',
        solid: true,
        autoHideDelay: 5000,
        noHoverPause: true,
        appendToast: false,
      });
    },

    finalPlanificacion(message)
    {
      this.$bvToast.toast(message, {
        title: 'Planificación finalizada exitosamente',
        variant: 'success',
        solid: true,
        autoHideDelay: 5000,
        noHoverPause: true,
        appendToast: false,
      });
    },





  },
};
</script>
<style scoped>
.component {
  margin-bottom: 3%;
}
</style>